@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Marck+Script&display=swap");
@import "aos/dist/aos.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	.max-width {
		max-width: 1400px;
	}
}

@layer components {
	a {
		@apply font-bold text-sm tracking-wide transition-colors duration-200;
	}
	a:hover {
		@apply text-primary;
	}
}
